.audiences-input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0;
  justify-content: center;
}

.audiences-container {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  background-color: #f6f6f6;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  min-width: 200px;
  text-align: center;
  width: 100%;
}

.new-audience-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.audience-value-input {
  width: 160px;
}

.new-audience-field {
  display: flex;
  flex-flow: column;
}

.existing-audience-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
}

.existing-audience-item-col {
  width: 100px;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.delete-audience-button {
  margin-left: auto;
}
