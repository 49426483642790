.notification-panel {
  max-height: 250px;
  min-width: 200px;
  max-width: 300px;
  overflow: auto;
}

.notification-panel::-webkit-scrollbar {
  width: 5px;
}

.notification-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notification-panel::-webkit-scrollbar-thumb {
  background: grey;
}

.notification-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-container {
  display: flex;
  flex-flow: column;
}

.notification-options {
  font-size: 10pt;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
}

.notification-options:hover {
  background: #d1d1d1;
}
