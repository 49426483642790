.user-header {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 10px 30px;
  min-height: 60px;
  justify-content: space-between;
  min-width: 0;
  flex-basis: 1;
}

.middle-info {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .user-header {
    flex-basis: auto;
  }

  .middle-info {
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
  }

  .logged-logo {
    margin-left: auto;
    margin-right: auto;
  }

  .logout-menu {
    margin-left: auto;
    margin-right: auto;
  }
}

.notification-bt {
  margin-left: 10px;
  margin-right: 30px;
}

.user-info {
  text-align: center;
}
