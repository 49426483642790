.site-layout-content {
  min-height: 300px;
  padding: 24px;
  background: white;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}

.logo {
  background-image: url("../../../public/logo.png");
  background-size: 200px;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6f6f6;
}

.site-layout-content .ant-form-item-label {
  padding: 5px 0 !important;
}

.content-box {
  padding: 20px;
  min-width: 200px;
  background: white;
  max-width: 800px;
  border-radius: 10px;
}

.form-title {
  text-align: center;
  font-size: 16;
  font-weight: bold;
  margin-bottom: 0;
}
