.report-cards {
  margin-right: 18px;
  margin-top: 10px;
}

.report-button {
  margin-left: auto;
}

.reports-table {
  margin-top: 18px;
}

.reports-table table {
  background: white;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
}
